import { CustomFieldType, ItemType } from 'daos/enums';
import {
  EstimationMappingType,
  EstimationType,
  SourceSystem,
  StoryPointSchemeActions,
  TaskGroupHierarchy,
} from 'daos/external_integration_enums';
import { ItemDao } from 'daos/item';
import { FolderStatus } from 'daos/item_enums';
import { JiraExternalIntegrationDao, JiraExternalIntegrationsUpdateDto } from 'daos/jira_external_integration';
import {
  CustomField,
  ExternalIntegrationFieldMapping,
  ExternalIntegrationSyncProjectJob,
  Item,
  JiraAccessibleResourcesView,
  JiraIssueFilter,
  JiraOauthResponse,
  JiraStoryPointsMapping,
  JiraSyncSettings,
  StoryPointScheme,
  User,
} from 'daos/model_types';
import {
  AllowedCustomField,
  FieldMappingValue,
  JiraCustomFieldType,
  JiraProjectModalFormFieldMapping,
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  JiraTab,
  JiraTabKey,
  lpNameSystemMapping,
  LpSystemId,
  storyPointsSchemeInitialValues,
  SyncErrorMessage,
  TabNames,
} from 'features/jira_project/modal/types';
import { formatStoryPointsForSave } from 'features/story_points/editable_story_points_list/formatting';
import { EditableStoryPoints } from 'features/story_points/editable_story_points_list/types';
import { ErrorCodes } from 'lib/api/types';
import { EntityLookupById } from 'redux/entities/types';

export const canNavigateToTab = ({
  name,
  completedTabs,
}: {
  name: TabNames;
  completedTabs: Record<JiraTabKey, boolean>;
}): boolean => {
  switch (name) {
    case TabNames.Welcome:
      return true;
    case TabNames.JiraInstanceAndCredentials:
      return true;
    case TabNames.ProjectAndIssuesFilter:
      return completedTabs[JiraTabKey.JiraInstance];
    case TabNames.SyncSettings:
      return completedTabs[JiraTabKey.ProjectAndIssuesFilter];
    case TabNames.FieldMapping:
      return completedTabs[JiraTabKey.SyncSettings];
    case TabNames.RemainingEstimates:
      return completedTabs[JiraTabKey.FieldMapping];
    case TabNames.Launch:
      return completedTabs[JiraTabKey.RemainingEstimates];
    default:
      return false;
  }
};

interface TabParams {
  activeTab: JiraTabKey;
  tabs: ReadonlyArray<JiraTab>;
}

const getCurrentTabIndex = ({ activeTab, tabs }: TabParams) => {
  const index = tabs.findIndex((tab) => tab.key === activeTab);
  const activeTabOrFirstIndex = Math.max(0, index);
  return activeTabOrFirstIndex;
};

export const getCurrentTabName = (activeTab: JiraTabKey) => {
  switch (activeTab) {
    case JiraTabKey.JiraInstance:
      return TabNames.JiraInstanceAndCredentials;
    case JiraTabKey.ProjectAndIssuesFilter:
      return TabNames.ProjectAndIssuesFilter;
    case JiraTabKey.SyncSettings:
      return TabNames.SyncSettings;
    case JiraTabKey.FieldMapping:
      return TabNames.FieldMapping;
    case JiraTabKey.RemainingEstimates:
      return TabNames.RemainingEstimates;
    case JiraTabKey.Launch:
      return TabNames.Launch;
    default:
      return '';
  }
};

export const getPreviousTab = ({ activeTab, tabs }: TabParams) => {
  const currentIndex = getCurrentTabIndex({ activeTab, tabs });
  return {
    previousTabKey: tabs[currentIndex - 1]?.key as JiraTabKey,
  };
};

interface GetCreatePayloadProps {
  packageId: number;
  jiraProjectName: string;
  formValues: JiraProjectModalFormValues;
}

export const getJiraProjectModalCreatePayload = ({
  packageId,
  jiraProjectName: name,
  formValues,
}: GetCreatePayloadProps): JiraExternalIntegrationDao => {
  const {
    jiraProjectId: sourceItemId,
    fieldMappings,
    lowEstimate,
    highEstimate,
    issueTypeIds,
    issueStatusIds,
    issueCreatedDate,
    costCodeId,
    syncJiraWorklog,
    syncTimesheetWorklog,
    taskGroupHierarchy,
    rankIssues,
    oauthCredentialsId: credentialsId,
    cloudId,
    estimationType,
    storyPointSchemeName,
    storyPointSchemeActions,
    existingSchemeId,
    storyPoints,
  } = formValues;

  const itemDto: Pick<Item, 'type' | 'name' | 'itemType' | 'folderStatus' | 'parent' | 'sourceSystem'> = {
    type: 'items',
    name,
    itemType: ItemType.PROJECTS,
    folderStatus: FolderStatus.ACTIVE,
    parent: ItemDao.id(packageId),
    sourceSystem: SourceSystem.JIRA,
  };

  const jiraAccessibleResourcesDto = {
    cloudId,
  };

  const externalIntegrationsDto = {
    credentialsId,
    sourceSystem: SourceSystem.JIRA,
    sourceItemId,
  };

  const jiraSyncSettingDto = {
    syncJiraWorklog,
    syncTimesheetWorklog,
    costCodeId: syncJiraWorklog || syncTimesheetWorklog ? costCodeId : null,
    lowEstimate,
    highEstimate,
    taskGroupHierarchy,
    rankIssues,
    estimationMapping: {
      estimationType,
      estimationSettingDto: getEstimationSettingDtoPayload({
        estimationType,
        lowEstimate,
        highEstimate,
        storyPointSchemeActions,
        storyPointSchemeName,
        existingSchemeId,
        storyPoints,
      }),
    },
  };

  const jiraIssueFilterDto = {
    issueStatusIds,
    issueTypeIds,
    issueCreatedDate,
  };

  return {
    itemDto,
    externalIntegrationsDto,
    jiraIssueFilterDto,
    jiraAccessibleResourcesDto,
    jiraSyncSettingDto,
    externalIntegrationFieldMappingDtos: fieldMappings,
  };
};

export const getEstimationSettingDtoPayload = ({
  estimationType,
  lowEstimate,
  highEstimate,
  storyPointSchemeActions,
  storyPointSchemeName,
  existingSchemeId,
  storyPoints,
}: {
  estimationType: EstimationType;
  lowEstimate?: number;
  highEstimate?: number;
  storyPointSchemeActions?: StoryPointSchemeActions;
  storyPointSchemeName?: string;
  existingSchemeId?: number | null;
  storyPoints?: Array<EditableStoryPoints> | null;
}) => {
  if (estimationType === EstimationType.RemainingTime) {
    return {
      type: EstimationMappingType.RemainingTime,
      lowEstimate: lowEstimate,
      highEstimate: highEstimate,
    };
  } else if (estimationType === EstimationType.StoryPoints) {
    return getStoryPointPayload({ storyPointSchemeActions, storyPointSchemeName, existingSchemeId, storyPoints });
  }
};

export const getStoryPointPayload = ({
  storyPointSchemeActions,
  storyPointSchemeName,
  existingSchemeId,
  storyPoints,
}: {
  storyPointSchemeActions?: StoryPointSchemeActions;
  storyPointSchemeName?: string;
  existingSchemeId?: number | null;
  storyPoints?: Array<EditableStoryPoints> | null;
}) => {
  switch (storyPointSchemeActions) {
    case StoryPointSchemeActions.UseDefault:
      return {
        type: EstimationMappingType.StoryPoints,
        action: storyPointSchemeActions,
      };
    case StoryPointSchemeActions.CreateNew:
      return {
        type: EstimationMappingType.StoryPoints,
        action: storyPointSchemeActions,
        storyPointSchemeDto: {
          displayName: storyPointSchemeName,
          storyPoints: storyPoints ? formatStoryPointsForSave(storyPoints) : null,
        },
      };
    case StoryPointSchemeActions.UseExisting:
      return {
        type: EstimationMappingType.StoryPoints,
        action: storyPointSchemeActions,
        storyPointSchemeDto: {
          id: existingSchemeId,
        },
      };
    default:
      return {};
  }
};

export const getJiraProjectModalUpdatePayload = ({
  formValues,
}: {
  formValues: JiraProjectModalFormValues;
}): JiraExternalIntegrationsUpdateDto => {
  const {
    fieldMappings,
    lowEstimate,
    highEstimate,
    issueTypeIds,
    issueStatusIds,
    issueCreatedDate,
    costCodeId,
    syncJiraWorklog,
    syncTimesheetWorklog,
    taskGroupHierarchy,
    rankIssues,
  } = formValues;

  const jiraIssueFilterDto = {
    issueStatusIds,
    issueTypeIds,
    issueCreatedDate,
  };

  const jiraSyncSettingDto = {
    costCodeId,
    syncJiraWorklog,
    syncTimesheetWorklog,
    lowEstimate,
    highEstimate,
    taskGroupHierarchy,
    rankIssues,
  };

  return {
    externalIntegrationFieldMappingDtos: fieldMappings,
    jiraIssueFilterDto,
    jiraSyncSettingDto,
  };
};

export const handleJiraProjectDetailsResponse = ({
  jiraIssueFilters: issueFilters,
  externalIntegrationFieldMappings: fieldMappings,
  jiraAccessibleResourcesView: accessibleResourcesView,
  jiraSyncProjectJobs: syncProjectJobs,
  jiraSyncSettings: syncSettings,
  users,
}: {
  jiraIssueFilters?: EntityLookupById<JiraIssueFilter>;
  externalIntegrationFieldMappings?: EntityLookupById<ExternalIntegrationFieldMapping>;
  jiraAccessibleResourcesView?: EntityLookupById<JiraAccessibleResourcesView>;
  jiraSyncProjectJobs?: EntityLookupById<ExternalIntegrationSyncProjectJob>;
  jiraSyncSettings?: EntityLookupById<JiraSyncSettings>;
  jiraOauthCredentialsUsers?: EntityLookupById<JiraOauthResponse>;
  users?: EntityLookupById<User>;
}) => {
  const jiraIssueFilters = issueFilters && Object.values(issueFilters)[0];
  const jiraFieldMappings = fieldMappings ? Object.values(fieldMappings) : [];
  const jiraAccessibleResourceView = accessibleResourcesView && Object.values(accessibleResourcesView)[0];
  const jiraSyncProjectJob = syncProjectJobs && Object.values(syncProjectJobs)[0];
  const jiraSyncSettings = syncSettings && Object.values(syncSettings)[0];
  const authorizedByUser = users && Object.values(users)[0];

  return {
    jiraIssueFilters,
    jiraFieldMappings,
    jiraSyncSettings,
    jiraAccessibleResourcesView: jiraAccessibleResourceView,
    authorizedByUser,
    jiraSyncProjectJob,
  };
};

export const appendQueryParamToRelativeUrl = (relativeUrl: string, queryParam: { [key: string]: string | number }) => {
  const newQuery = Object.entries(queryParam)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const hashIndex = relativeUrl.indexOf('#');
  const hash = hashIndex !== -1 ? relativeUrl.substring(hashIndex) : '';
  const baseUrlWithPossibleQuery = hashIndex !== -1 ? relativeUrl.substring(0, hashIndex) : relativeUrl;

  const queryIndex = baseUrlWithPossibleQuery.indexOf('?');
  const query = queryIndex !== -1 ? baseUrlWithPossibleQuery.substring(queryIndex + 1) : '';
  const baseUrl = queryIndex !== -1 ? baseUrlWithPossibleQuery.substring(0, queryIndex) : baseUrlWithPossibleQuery;

  const combinedQuery = query ? `${query}&${newQuery}` : newQuery;

  const newUrl = `${baseUrl}?${combinedQuery}${hash}`;

  return newUrl;
};

export const getSyncErrorMessage = (errorCode: ErrorCodes) => {
  switch (errorCode) {
    case ErrorCodes.JiraInvalidResource:
      return SyncErrorMessage.InvalidResource;
    case ErrorCodes.JiraProjectNotFound:
      return SyncErrorMessage.JiraProjectNotFound;
  }
};

const getFieldsToTouchForActiveTab = ({ activeTab }: { activeTab: JiraTabKey }) => {
  switch (activeTab) {
    case JiraTabKey.JiraInstance:
      return [JiraProjectModalFormFields.OauthCredentialsId, JiraProjectModalFormFields.CloudId];
    case JiraTabKey.ProjectAndIssuesFilter:
      return [
        JiraProjectModalFormFields.JiraProjectId,
        JiraProjectModalFormFields.IssueTypeIds,
        JiraProjectModalFormFields.IssueStatusIds,
        JiraProjectModalFormFields.IssueCreatedDate,
      ];
    default:
      return [];
  }
};

export const hasErrorsInActiveTab = async ({
  activeTab,
  setFieldTouched,
}: {
  activeTab: JiraTabKey;
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
}) => {
  const fieldsToTouch = getFieldsToTouchForActiveTab({ activeTab });
  const errorsArray = await Promise.all(fieldsToTouch.map((field) => setFieldTouched(field, true, true)));
  const errors = errorsArray.reduce((acc, error) => Object.assign(acc, error), {});

  return Object.keys(errors).length > 0;
};

const getIssueTypeNames = (
  issueTypesSelected?: Array<string>,
  issueTypes?: ReadonlyArray<{ name: string; id: string }> | undefined
) => {
  return (
    issueTypesSelected
      ?.map((issueType) => {
        const issue = issueTypes?.find((issue) => issue.id === issueType);
        return issue?.name;
      })
      .filter(Boolean) || []
  );
};

export const getPushedIssueTypes = (
  issueTypesSelected?: Array<string>,
  issueTypes?: ReadonlyArray<{ name: string; id: string }> | undefined
) => {
  const issueTypeNames = getIssueTypeNames(issueTypesSelected, issueTypes);

  if (issueTypeNames && issueTypeNames.length > 1) {
    return issueTypeNames.join(',');
  } else if (issueTypeNames && issueTypeNames.length === 1) {
    return issueTypeNames[0];
  } else {
    return '';
  }
};

export const getInitialValues = ({
  jiraIssueFilters,
  externalIntegrationFieldMappings,
  jiraSyncSettings,
  jiraProjectId,
  jiraCloudId,
  oAuthCredentialsId,
  defaultCostCode,
}: {
  jiraIssueFilters?: JiraIssueFilter;
  externalIntegrationFieldMappings?: ReadonlyArray<ExternalIntegrationFieldMapping>;
  jiraSyncSettings?: JiraSyncSettings;
  jiraProjectId?: string;
  jiraCloudId?: string;
  oAuthCredentialsId?: number;
  defaultCostCode?: number;
}): JiraProjectModalFormValues => {
  return {
    [JiraProjectModalFormFields.CloudId]: jiraCloudId ?? '',
    [JiraProjectModalFormFields.OauthCredentialsId]: oAuthCredentialsId ?? 0,
    [JiraProjectModalFormFields.JiraProjectId]: jiraProjectId ?? '',
    [JiraProjectModalFormFields.IssueTypeIds]: (jiraIssueFilters?.issueTypeIds as Array<string>) ?? [],
    [JiraProjectModalFormFields.IssueStatusIds]: (jiraIssueFilters?.issueStatusIds as Array<string>) ?? [],
    [JiraProjectModalFormFields.IssueCreatedDate]: jiraIssueFilters?.issueCreatedDate ?? '',
    [JiraProjectModalFormFields.FieldMappings]:
      externalIntegrationFieldMappings?.map((fieldMapping) => ({
        lpCustomFieldId: fieldMapping.lpCustomFieldId,
        lpSystemField: fieldMapping.lpSystemField,
        fieldType: fieldMapping.fieldType,
        sourceFieldId: fieldMapping.sourceFieldId,
        isPull: fieldMapping.isPull,
        isPush: fieldMapping.isPush,
      })) ?? [],
    [JiraProjectModalFormFields.LowEstimate]: jiraSyncSettings?.lowEstimate ?? -20,
    [JiraProjectModalFormFields.HighEstimate]: jiraSyncSettings?.highEstimate ?? 20,
    [JiraProjectModalFormFields.SyncJiraWorklog]: jiraSyncSettings?.syncJiraWorklog ?? false,
    [JiraProjectModalFormFields.SyncTimesheetWorklog]: jiraSyncSettings?.syncTimesheetWorklog ?? false,
    [JiraProjectModalFormFields.TaskGroupHierarchy]:
      jiraSyncSettings?.taskGroupHierarchy ?? TaskGroupHierarchy.GroupFlatLevel,
    [JiraProjectModalFormFields.RankIssues]: jiraSyncSettings?.rankIssues ?? true,
    [JiraProjectModalFormFields.CostCodeId]: jiraSyncSettings?.costCodeId ?? defaultCostCode ?? null,
    [JiraProjectModalFormFields.EstimationType]:
      jiraSyncSettings?.estimationMapping?.estimationType ?? EstimationType.RemainingTime,
    [JiraProjectModalFormFields.StoryPointSchemeActions]:
      jiraSyncSettings?.estimationMapping.estimationSettingDto.action ?? StoryPointSchemeActions.CreateNew,
    [JiraProjectModalFormFields.StoryPointSchemeName]:
      jiraSyncSettings?.estimationMapping.estimationSettingDto.storyPointSchemeDto?.displayName ?? '',
    [JiraProjectModalFormFields.ExistingSchemeId]:
      jiraSyncSettings?.estimationMapping.estimationSettingDto.storyPointSchemeDto?.id ?? null,
    [JiraProjectModalFormFields.StoryPoints]: jiraSyncSettings?.estimationMapping.estimationSettingDto
      .storyPointSchemeDto?.storyPoints
      ? convertStoryPointsToString(
          jiraSyncSettings?.estimationMapping.estimationSettingDto.storyPointSchemeDto?.storyPoints
        )
      : convertStoryPointsToString(storyPointsSchemeInitialValues),
    [JiraProjectModalFormFields.SelectedExistingSchemeToCopy]: null,
  };
};

export const convertStoryPointsToString = (storyPoints: ReadonlyArray<JiraStoryPointsMapping>) => {
  return storyPoints.map(({ value, highEffort, lowEffort }) => ({
    value: value.toString(),
    highEffort: highEffort?.toString(),
    lowEffort: lowEffort?.toString(),
  }));
};

export const populateLpToJiraRows = ({
  fieldMappings,
  lpCustomFields,
}: {
  fieldMappings: ReadonlyArray<JiraProjectModalFormFieldMapping>;
  lpCustomFields: ReadonlyArray<CustomField>;
}): Set<FieldMappingValue> => {
  const pushOnlyFieldMappings = fieldMappings
    .filter(({ isPush, isPull }) => isPush && !isPull)
    .map(({ lpCustomFieldId, lpSystemField, fieldType }) => ({
      id: lpCustomFieldId?.toString() ?? lpSystemField ?? '',
      type: fieldType as AllowedCustomField,
      name: getLpFieldName(lpCustomFieldId ?? lpSystemField ?? '', lpCustomFields) ?? '',
    }));
  return new Set(pushOnlyFieldMappings);
};

export const populateJiraToLpRows = ({
  fieldMappings,
  jiraProjectMappingFieldData,
}: {
  fieldMappings: ReadonlyArray<JiraProjectModalFormFieldMapping>;
  jiraProjectMappingFieldData: ReadonlyArray<FieldMappingValue>;
}): Set<FieldMappingValue> => {
  const pullFieldMappings = fieldMappings
    .filter(({ isPull }) => isPull)
    .map(({ sourceFieldId }) => {
      const jiraProjectMappingField = jiraProjectMappingFieldData.find((field) => field.id === sourceFieldId);
      return {
        id: sourceFieldId,
        type: jiraProjectMappingField
          ? convertFieldTypeToAllowedFieldType(jiraProjectMappingField)
          : CustomFieldType.TEXT,
        name: jiraProjectMappingField?.name ?? '',
        custom: jiraProjectMappingField?.custom,
        isEditable: !!jiraProjectMappingField?.isEditable,
      };
    });
  return new Set(pullFieldMappings);
};

const getLpFieldName = (fieldId: string | number, lpCustomFields: ReadonlyArray<CustomField>) => {
  const isLpSystemField = getIsLpSystemField(fieldId.toString());
  return isLpSystemField
    ? lpNameSystemMapping.find(({ id }) => id === fieldId.toString())?.name
    : lpCustomFields.find((field) => field.id === Number(fieldId))?.name;
};

export const getIsLpSystemField = (fieldId: string) => Object.values(LpSystemId).includes(fieldId as LpSystemId);

export const convertFieldTypeToAllowedFieldType = ({ custom, type }: FieldMappingValue): AllowedCustomField => {
  if (type === 'datetime' || type === CustomFieldType.DATE) {
    return CustomFieldType.DATE;
  }
  if (type === 'number' || type === CustomFieldType.NUMERIC) {
    return CustomFieldType.NUMERIC;
  }
  if (type === 'array' || type === CustomFieldType.MULTI_PICKLIST) {
    return CustomFieldType.MULTI_PICKLIST;
  }
  if (type === 'option' || type === CustomFieldType.PICKLIST) {
    return CustomFieldType.PICKLIST;
  }
  if (type === CustomFieldType.LINK || custom === JiraCustomFieldType.Url) {
    return CustomFieldType.LINK;
  }
  return CustomFieldType.TEXT;
};

export const getAvailableFieldValueOptions =
  <T extends { id: string | number }>(selectedIds: Set<string | number | null>, options: ReadonlyArray<T>) =>
  (selectedId: string | undefined) =>
    options.filter((option) => option.id === selectedId || !selectedIds.has(option.id));

export const getExistingStoryPointsSchemesNames = (storyPointSchemes: ReadonlyArray<StoryPointScheme>) => {
  return storyPointSchemes.map((scheme) => scheme.displayName);
};
