import { toNumber } from 'lodash/fp';

import { StoryPoints } from 'daos/model_types';
import { timeStringInSecondsFromTimeStringWithUnits } from 'features/item_panel/sections/planning_section/limits/work_limit/helpers';

import { EditableStoryPoints } from './types';

export const formatStoryPointsForSave = (storyPoints: ReadonlyArray<EditableStoryPoints>) =>
  storyPoints.map(({ id, value, highEffort, lowEffort }) => {
    return {
      id,
      value: toNumber(value),
      highEffort: highEffort ? toNumber(timeStringInSecondsFromTimeStringWithUnits(highEffort)) : undefined,
      lowEffort: lowEffort ? toNumber(timeStringInSecondsFromTimeStringWithUnits(lowEffort)) : undefined,
    };
  });

export const formatEditedStoryPointsForSave = (
  formStoryPoints: ReadonlyArray<EditableStoryPoints>,
  initialStoryPoints: ReadonlyArray<StoryPoints>
) => {
  const formattedStoryPoints: Array<Partial<StoryPoints>> = [];

  initialStoryPoints.forEach((initialPoint) => {
    const formStoryPoint = formStoryPoints.find((formPoint) => formPoint.id === initialPoint.id);

    if (!formStoryPoint) {
      formattedStoryPoints.push({ id: initialPoint.id, delete: true });
    }
  });

  formStoryPoints.forEach((formPoint) => {
    const { id, value, highEffort, lowEffort } = formPoint;

    formattedStoryPoints.push({
      id,
      value: toNumber(value),
      highEffort: highEffort ? toNumber(timeStringInSecondsFromTimeStringWithUnits(highEffort)) : null,
      lowEffort: lowEffort ? toNumber(timeStringInSecondsFromTimeStringWithUnits(lowEffort)) : null,
    });
  });

  return formattedStoryPoints;
};
