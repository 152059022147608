import { useFormikContext } from 'formik';

import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { isDefaultStoryPointScheme } from 'features/jira_project/modal/sections/estimate_mapping_section/helpers';
import { StoryPointsSchemeChoice } from 'features/jira_project/modal/sections/estimate_mapping_section/story_points_scheme_choice';
import { StoryPointsSchemeEditableContent } from 'features/jira_project/modal/sections/estimate_mapping_section/story_points_scheme_editable_content';
import {
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  SchemeDisplayNames,
} from 'features/jira_project/modal/types';
import { useStoryPointSchemesWithDefault } from 'features/story_points/story_points_scheme_modal/use_story_point_schemes_with_default';

export const StoryPointsScheme = () => {
  const { selectedSchemeActionDisplayName, setSelectedSchemeActionDisplayName } = useJiraProjectModalContext();
  const { storyPointSchemesRowData: schemesWithDefaultData } = useStoryPointSchemesWithDefault();
  const { values } = useFormikContext<JiraProjectModalFormValues>();
  const selectedExistingSchemeToCopyValue = values[JiraProjectModalFormFields.SelectedExistingSchemeToCopy];
  const existingSchemeToUse = values[JiraProjectModalFormFields.ExistingSchemeId];
  const isDefaultScheme = isDefaultStoryPointScheme(schemesWithDefaultData, existingSchemeToUse);
  const isReadOnly = !!existingSchemeToUse;
  const selectedSchemeId = selectedExistingSchemeToCopyValue || existingSchemeToUse;
  const shouldShowSchemeEditableContent =
    selectedSchemeActionDisplayName === SchemeDisplayNames.CreateNewScheme ||
    !!selectedExistingSchemeToCopyValue ||
    !!existingSchemeToUse;

  return (
    <>
      <StoryPointsSchemeChoice
        schemesWithDefaultData={schemesWithDefaultData}
        selectedSchemeActionDisplayName={selectedSchemeActionDisplayName}
        setSelectedSchemeActionDisplayName={setSelectedSchemeActionDisplayName}
      />
      {shouldShowSchemeEditableContent && (
        <StoryPointsSchemeEditableContent
          selectedSchemeId={selectedSchemeId}
          isReadOnly={isReadOnly}
          isDefaultScheme={isDefaultScheme}
        />
      )}
    </>
  );
};
