import { useFormikContext } from 'formik';
import React from 'react';
import { Input, InputProps, Popup, Table } from 'semantic-ui-react';

import { JiraProjectModalTableNameCell } from 'features/jira_project/modal/common/jira_project_modal_table_name_cell';
import { JiraProjectModalTableRow } from 'features/jira_project/modal/common/jira_project_modal_table_row';
import { JiraProjectModalFormFields, JiraProjectModalFormValues } from 'features/jira_project/modal/types';

export const nameAlreadyExistsError = 'Name already exists. Enter a new one.';

export const SchemeNameInput = () => {
  const { setFieldError, values, setFieldValue, errors } = useFormikContext<JiraProjectModalFormValues>();
  const error = errors[JiraProjectModalFormFields.StoryPointSchemeName];
  const storyPointSchemeName = values[JiraProjectModalFormFields.StoryPointSchemeName];
  const nameAlreadyExists = error === nameAlreadyExistsError;

  const handleChange = (_: React.SyntheticEvent, { value }: InputProps) => {
    setFieldError(JiraProjectModalFormFields.StoryPointSchemeName, undefined);
    setFieldValue(JiraProjectModalFormFields.StoryPointSchemeName, value);
  };

  return (
    <JiraProjectModalTableRow>
      <JiraProjectModalTableNameCell content={<span>{'Story Point Scheme Name'}</span>} />
      <Table.Cell className="jira-project-modal__content-estimate-mapping-story-point-scheme-name">
        {nameAlreadyExists ? (
          <Popup
            content={error}
            className="jira-project-modal__content-estimate-mapping-story-point-scheme-name-popup"
            open={true}
            position="right center"
            hoverable={true}
            trigger={
              <StoryPointSchemeNameInput
                storyPointSchemeName={storyPointSchemeName}
                handleChange={handleChange}
                error={!!error}
              />
            }
          />
        ) : (
          <StoryPointSchemeNameInput
            storyPointSchemeName={storyPointSchemeName}
            handleChange={handleChange}
            error={!!error}
          />
        )}
      </Table.Cell>
    </JiraProjectModalTableRow>
  );
};

const StoryPointSchemeNameInput = ({
  handleChange,
  storyPointSchemeName,
  error,
}: {
  handleChange: (_: React.SyntheticEvent, { value }: InputProps) => void;
  storyPointSchemeName: string;
  error: boolean;
}) => {
  return (
    <Input
      onChange={handleChange}
      value={storyPointSchemeName}
      name={JiraProjectModalFormFields.StoryPointSchemeName}
      error={!!error}
    />
  );
};
