import classNames from 'classnames';
import { ReactNode } from 'react';
import { Table } from 'semantic-ui-react';

const getMandatoryAsterisk = (isReadOnly: boolean) => {
  return !isReadOnly ? '*' : '';
};

export const JiraProjectTable = ({
  children,
  title,
  optionText = false,
  actionButton,
  isEmpty,
  isStoryPointsSchemeTable = false,
  isReadOnly = false,
}: {
  children: ReactNode;
  title?: string;
  optionText?: boolean;
  actionButton?: ReactNode;
  isEmpty?: boolean;
  isStoryPointsSchemeTable?: boolean;
  isReadOnly?: boolean;
}) => (
  <section>
    <div className="jira-project-modal__content-info-helper-container">
      <div className="jira-project-modal__content-info-helper-text">
        {title} {optionText && <span className="jira-project-modal__content-info-helper-text-italic">(Optional)</span>}
      </div>
    </div>
    <div className="jira-project-modal__content-table-action-button">{actionButton}</div>
    {isStoryPointsSchemeTable && (
      <div className="jira-project-modal__content-table-estimate-mapping-title">
        <div className="jira-project-modal__content-table-estimate-mapping-title-text">
          {`Story Points${getMandatoryAsterisk(isReadOnly)}`}
        </div>
        <div className="jira-project-modal__content-table-estimate-mapping-title-text">
          {`Remaining Estimates (Low-High)${getMandatoryAsterisk(isReadOnly)}`}
        </div>
      </div>
    )}
    <Table
      celled
      definition
      compact
      className={classNames(
        'custom-field-table',
        'jira-project-modal__content-table',
        isStoryPointsSchemeTable && 'jira-project-modal__content-table--story-points-scheme',
        isEmpty && 'jira-project-modal__content-table--empty'
      )}
    >
      <Table.Body>{children}</Table.Body>
    </Table>
  </section>
);
