import { useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { JiraProjectModalInfo } from 'features/jira_project/modal/common/jira_project_modal_info';
import { JiraProjectTable } from 'features/jira_project/modal/common/jira_project_table';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { getSelectedSchemeStoryPoints } from 'features/jira_project/modal/sections/estimate_mapping_section/helpers';
import { StoryPointsSchemeFields } from 'features/jira_project/modal/sections/estimate_mapping_section/story_points_scheme_fields';
import { JiraProjectModalFormFields, JiraProjectModalFormValues } from 'features/jira_project/modal/types';
import { configureStoryPointsText } from 'features/story_points/editable_story_points_list/helpers';
import { EditableStoryPoints } from 'features/story_points/editable_story_points_list/types';
import { validateOne } from 'features/story_points/editable_story_points_list/validation';
import { getStoryPointsForSchemeId } from 'redux/entities/selectors/story_points';

export const StoryPointsSchemeEditableContent = ({
  selectedSchemeId,
  isReadOnly,
  isDefaultScheme,
}: {
  selectedSchemeId: number | null;
  isReadOnly: boolean;
  isDefaultScheme: boolean;
}) => {
  const { hasEditedStoryPoints, setHasEditedStoryPoints } = useJiraProjectModalContext();
  const { values, setFieldValue } = useFormikContext<JiraProjectModalFormValues>();
  const storyPoints = values[JiraProjectModalFormFields.StoryPoints] as Array<EditableStoryPoints>;
  const selectedSchemeStoryPoints = useSelector((state) =>
    selectedSchemeId ? getStoryPointsForSchemeId(state, selectedSchemeId) : null
  );

  const setStoryPoints = useCallback(
    (editableStoryPoints: Array<EditableStoryPoints>) => setFieldValue('storyPoints', editableStoryPoints),
    [setFieldValue]
  );

  const addStoryPoints = () => {
    setStoryPoints([...storyPoints, { value: '' }]);
    setHasEditedStoryPoints(true);
  };

  const onRemoveStoryPoints = (index: number) => {
    setStoryPoints(storyPoints.filter((_, i) => i !== index));
    setHasEditedStoryPoints(true);
  };

  const onStoryPointsChange = (index: number, next: EditableStoryPoints) => {
    const storyPointValues = storyPoints.map((storyPoint) => storyPoint.value);
    storyPointValues.splice(index, 1, next.value);

    setStoryPoints(storyPoints.map((current, i) => (i === index ? validateOne(next, storyPointValues) : current)));
    setHasEditedStoryPoints(true);
  };

  useEffect(() => {
    if (selectedSchemeStoryPoints && !hasEditedStoryPoints) {
      const formattedSelectedSchemeStoryPoints = getSelectedSchemeStoryPoints(selectedSchemeStoryPoints);
      setStoryPoints(formattedSelectedSchemeStoryPoints);
    }
  }, [selectedSchemeStoryPoints, setStoryPoints, hasEditedStoryPoints]);

  const storyPointTableTitle = isDefaultScheme ? 'Default Scheme' : 'Configure Story Points';
  const storyPointTableHelperText = isDefaultScheme
    ? 'Preview of the Selected Scheme. To make adjustments, please create a new scheme by selecting Create Custom Scheme.'
    : configureStoryPointsText;

  return (
    <div>
      {!isReadOnly || isDefaultScheme ? (
        <JiraProjectModalInfo title={storyPointTableTitle} helperText={storyPointTableHelperText} />
      ) : null}
      <JiraProjectTable
        isStoryPointsSchemeTable={true}
        isReadOnly={isReadOnly}
        actionButton={
          !isReadOnly && (
            <Button primary type="button" onClick={addStoryPoints}>
              + Story Points
            </Button>
          )
        }
      >
        {storyPoints.map((storyPoint, index) => (
          <StoryPointsSchemeFields
            key={`${storyPoint.id ?? 0}_${index}`}
            onRemoveStoryPoints={onRemoveStoryPoints}
            onStoryPointsChange={onStoryPointsChange}
            index={index}
            id={storyPoint.id}
            value={storyPoint.value}
            lowEffort={storyPoint.lowEffort}
            highEffort={storyPoint.highEffort}
            errors={storyPoint.errors}
            isReadOnly={isReadOnly}
          />
        ))}
      </JiraProjectTable>
    </div>
  );
};
